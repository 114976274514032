(function (w, d, searxng) {
  const settingsDrawer = d.querySelector(".settings-drawer");
  if (!settingsDrawer) {
    return;
  }
  const mostVisitedAvailable = w.chrome.embeddedSearch?.newTabPage.mostVisitedAvailable ?? false;
  const mostVisited = mostVisitedAvailable ? w.chrome.embeddedSearch.newTabPage.mostVisited : [];
  const hamburgerMenuButton = d.querySelector(".hamburger-menu");
  const closeMenuButton = d.querySelector(".hamburger-menu-close");
  const settingsContainer = settingsDrawer.querySelector(".settings");
  const newTabSettings = {
    hidePopularSites: false,
    layout: { value: "light", text: "Light Mode", theme: "light" },
    searchProvider: { value: "givero", text: "Givero" },
  };
  const newTabConfig = {
    extensionId: "idpgdfkhjbbcmnfoolkhefclommfmfbn",
    settings: [
      {
        name: "searchProvider",
        type: "select",
        label: "Search Provider",
        cookieName: "searchProvider",
        options: [
          { value: "bing", text: "Bing" },
          { value: "givero", text: "Givero" },
          { value: "yahoo", text: "Yahoo" },
          { value: "google", text: "Google" },
        ],
      },
      {
        name: "layout",
        type: "select",
        label: "Layout",
        cookieName: "background",
        options: [
          { value: "light", text: "Light Mode", theme: "light" },
          { value: "dark", text: "Dark Mode", theme: "dark" },
          { value: "ocean", text: "Ocean", theme: "dark" },
          { value: "forest", text: "Forest", theme: "dark" },
          { value: "desert", text: "Desert", theme: "light" },
          { value: "snow", text: "Snow", theme: "light" },
        ],
      },
      {
        name: "hidePopularSites",
        type: "toggle",
        label: "Hide Popular Sites",
        cookieName: "hide_popular_sites",
      },
    ],
  };

  function toggleSettingsDrawer () {
    settingsDrawer.classList.toggle("active");
  }

  function setupHamburgerMenu () {
    if (!hamburgerMenuButton || !settingsDrawer) {
      return;
    }

    hideWhenNoExtension();
    attachHamburgerMenuListeners();
    initializeSettingsControls();
  }

  function attachHamburgerMenuListeners () {
    hamburgerMenuButton.addEventListener("click", toggleSettingsDrawer);
    closeMenuButton.addEventListener("click", toggleSettingsDrawer);

    d.addEventListener("click", (event) => {
      if (event.target === hamburgerMenuButton) {
        return;
      }

      if (!settingsDrawer.contains(event.target) && settingsDrawer.classList.contains("active")) {
        toggleSettingsDrawer();
      }
    });
  }

  function hideWhenNoExtension () {
    settingsContainer.style.opacity = mostVisitedAvailable ? 1 : 0;
    settingsContainer.style.display = mostVisitedAvailable ? "flex" : "none";

    const noExtText = settingsDrawer.querySelector(".no-ext-text");
    noExtText.style.display = mostVisitedAvailable ? "none" : "block";
  }

  function saveSettings () {
    for (const { name, cookieName, options } of newTabConfig.settings) {
      switch (name) {
      case "layout": {
        const { theme, value: background } =
            options.find(({ text }) => text === newTabSettings.layout.text) ??
            {};
        if (theme) {
          setNewTabCookie("simple_style", theme);
        }
        if (background) {
          setNewTabCookie("background", background);
        }
        break;
      }
      case "searchProvider": {
        const { value: searchProvider } = newTabSettings.searchProvider;
        if (searchProvider) {
          setNewTabCookie("searchProvider", searchProvider);
          setSearchProvider(searchProvider);
        }
        break;
      }
      case "hidePopularSites": {
        setNewTabCookie(cookieName, !!newTabSettings.hidePopularSites);
      }
      }
    }
  }

  function setSearchProvider (searchProvider) {
    const searchProviderInput = d.querySelector("#search_provider");
    searchProviderInput.value = searchProvider;
  }

  function getCookie (cookieName) {
    const cookies = d.cookie.split(";").map((cookie) => cookie.trim().split("="));
    const [, cookieValue] = cookies?.find(([name]) => name === cookieName) ?? [];
    return cookieValue;
  }

  function setNewTabCookie (cookieName, cookieValue) {
    d.cookie = `${cookieName}=${cookieValue}; path=/new_tab;`;
  }

  function loadSettings () {
    for (const setting of newTabConfig.settings) {
      const cookieValue = getCookie(setting.cookieName);
      if (!cookieValue) {
        return;
      }
      switch (setting.name) {
      case "hidePopularSites":
        newTabSettings[setting.name] = (cookieValue === "true");
        break;
      case "layout":
      case "searchProvider": {
        const selectOptions = newTabConfig.settings.find(
          ({ name }) => name === setting.name
        ).options;
        newTabSettings[setting.name] = selectOptions?.find(
          ({ value: selectValue }) => selectValue === cookieValue
        );
        break;
      }
      }
    }
  }

  function applySettings () {
    for (const [settingName, settingValue] of Object.entries(newTabSettings)) {
      const settingElement = settingsDrawer.querySelector(
        `[data-select-id="${settingName}"]`
      );
      if (settingElement) {
        const selectedSettingType = newTabConfig.settings.find(({ name }) => name === settingName)?.type;
        switch (selectedSettingType) {
        case "select": {
          const currentSelect = settingsDrawer.querySelector(`[data-select-id="${settingName}"]`);
          const selectOptions = currentSelect?.querySelectorAll(".custom-option");
          for (const option of selectOptions) {
            if (option.textContent === settingValue.text) {
              currentSelect.querySelector(".custom-select-trigger").textContent = settingValue.text;
              option.classList.add("selected");
              continue;
            }
            option.classList.remove("selected");
          }

          if (settingName === "layout") {
            const themeBg = Array.from(d.documentElement.classList).find(className => className.startsWith("bg-"));
            if (themeBg) {
              d.documentElement.classList.replace(themeBg, `bg-${settingValue.value}`);
            } else {
              d.documentElement.classList.add(`bg-${settingValue.value}`);
            }
            const themeClass = Array.from(d.documentElement.classList).find(className => className.startsWith("theme-"));
            if (themeClass) {
              d.documentElement.classList.replace(themeClass, `theme-${settingValue.theme}`);
            } else {
              d.documentElement.classList.add(`theme-${settingValue.theme}`);
            }
            initializeTopSites();
          } else if (settingName === "searchProvider") {
            setSearchProvider(settingValue.value);
          }
          break;
        }
        case "toggle": {
          settingElement.classList.toggle("active", settingValue);
          setToggle(settingName, settingValue);
          break;
        }
        }
      }
    }
  }

  function setToggle (settingName, settingValue) {
    switch (settingName) {
    case "hidePopularSites": {
      const topSitesContainer = d.querySelector(".topsites-container");
      if (topSitesContainer) {
        topSitesContainer.style.display = settingValue ? "none" : "flex";
      }
      break;
    }
    }
  }

  function initializeSettingsControls () {
    for (const setting of newTabConfig.settings) {
      const {
        name: settingName,
        type: settingType,
        label: settingLabel,
        options: settingOptions,
      } = setting;
      const settingContainer = d.createElement("div");
      settingContainer.classList.add("setting-container", settingType);
      const label = d.createElement("label");
      label.setAttribute("for", `select-${settingName}`);
      label.textContent = settingLabel;

      switch (settingType) {
      case "toggle": {
        addToggle(settingName, settingContainer, label);
        settingsContainer.appendChild(settingContainer);
        break;
      }
      case "select": {
        addSelect(settingName, settingContainer, label, settingOptions);
        settingsContainer.appendChild(settingContainer);
        searxng.customSelect(
          `.custom-select-wrapper[data-select-id="${settingName}"]`
        );
        break;
      }
      }
    }

    loadSettings();

    if (mostVisitedAvailable) {
      applySettings();
      updateToggleSettings();
      initializeTopSites();
      saveSettings();
    }
  }

  function updateToggleSettings () {
    for (const toggle of settingsDrawer.querySelectorAll(".toggle-switch")) {
      newTabSettings[toggle.dataset.selectId] =
        toggle.classList.contains("active");

      for (const eventName of ["click", "keydown"]) {
        toggle.addEventListener(eventName, function (event) {
          if (eventName === "keydown" && !["Enter", "Spacebar", " "].includes(event.key)) {
            return;
          }
          toggle.addEventListener("click", function () {
            newTabSettings[toggle.dataset.selectId] = toggle.classList.toggle("active");
            saveSettings();
            applySettings();
          });
        });
      }
    }
  }

  function addSelect (settingName, settingContainer, label, settingOptions) {
    const selectWrapper = d.createElement("div");
    selectWrapper.classList.add("custom-select-wrapper");
    selectWrapper.name = `select-${settingName}`;
    selectWrapper.dataset.selectId = settingName;

    const customSelect = d.createElement("div");
    customSelect.classList.add("custom-select");
    customSelect.tabIndex = 0;

    const customSelectTrigger = d.createElement("span");
    customSelectTrigger.classList.add("custom-select-trigger");

    const chevron = d.createElement("span");
    chevron.classList.add("chevron");

    const customOptions = d.createElement("div");
    customOptions.classList.add("custom-options");

    for (const option of settingOptions) {
      const customOption = d.createElement("span");
      customOption.classList.add("custom-option");
      customOption.tabIndex = 0;
      customOption.textContent = option.text;
      customOptions.appendChild(customOption);

      for (const eventName of ["click", "keydown"]) {
        customOption.addEventListener(eventName, function () {
          if (eventName === "keydown" && !["Enter", "Spacebar", " "].includes(event.key)) {
            return;
          }
          newTabSettings[settingName] = option;
          saveSettings();
          applySettings();
        });
      }
    }

    const selectInput = d.createElement("input");
    selectInput.type = "hidden";
    selectInput.name = settingName;
    selectInput.id = settingName;

    customSelect.appendChild(customSelectTrigger);
    customSelect.appendChild(chevron);
    customSelect.appendChild(customOptions);
    selectWrapper.appendChild(customSelect);
    settingContainer.appendChild(label);
    settingContainer.appendChild(selectWrapper);
    settingContainer.appendChild(selectInput);
  }

  function addToggle (settingName, settingContainer, label) {
    const toggle = d.createElement("div");
    toggle.classList.add("toggle-switch");
    toggle.classList.toggle("active", newTabSettings[settingName]);
    toggle.dataset.selectId = settingName;
    toggle.tabIndex = 0;
    const toggleKnob = d.createElement("span");
    toggleKnob.classList.add("toggle-knob");
    toggleKnob.name = "popular-sites-toggle";
    toggle.appendChild(toggleKnob);
    settingContainer.appendChild(toggle);
    settingContainer.appendChild(label);
  }

  function initializeTopSites () {
    const oldTopSitesContainer = d.querySelector(".topsites-container");
    if (oldTopSitesContainer) {
      oldTopSitesContainer.remove();
    }

    const topSitesContainer = d.createElement("div");
    topSitesContainer.classList.add("topsites-container");

    if (newTabSettings.hidePopularSites) {
      topSitesContainer.style.display = "none";
    }

    for (const { rid, faviconUrl } of mostVisited) {
      const faviconContainer = d.createElement("span");
      faviconContainer.classList.add("topsites-favicon-container");
      const favicon = d.createElement("img");
      favicon.src = faviconUrl;
      favicon.classList.add("topsites-favicon");
      faviconContainer.appendChild(favicon);

      const themesConfig = newTabConfig.settings.find(
        ({ type, name }) => type === "select" && name === "layout"
      );
      const isLightMode = !!themesConfig?.options?.filter(
        ({ value, theme }) => value === newTabSettings.layout.value && theme === "light"
      );
      const iframeBaseUrl = `chrome-search://most-visited/title.html?rid=${rid}`;

      const topSiteIframe = d.createElement("iframe");
      const topSiteIframeUrl = new URL(iframeBaseUrl);
      const topSiteIframeUrlParams = {
        c: isLightMode ? "000000" : "FFFFFF",
        f: "sans-serif",
        fs: "12",
        pos: "10",
        ta: "center"
      };
      for (const [key, value] of Object.entries(topSiteIframeUrlParams)) {
        topSiteIframeUrl.searchParams.append(key, value);
      }
      topSiteIframe.src = topSiteIframeUrl.toString();
      topSiteIframe.classList.add("topsites-iframe");

      const linkIframe = d.createElement("iframe");
      const linkIframeUrl = new URL(iframeBaseUrl);
      linkIframeUrl.searchParams.append("fs", "0");
      linkIframe.src = linkIframeUrl.toString();
      linkIframe.classList.add("topsites-link-iframe");

      const container = d.createElement("div");
      container.classList.add("topsites-entry");

      container.appendChild(faviconContainer);
      container.appendChild(linkIframe);
      container.appendChild(topSiteIframe);
      topSitesContainer.appendChild(container);
    }

    const searchBar = d.getElementById("search");
    searchBar.insertAdjacentElement("afterend", topSitesContainer);
  }

  searxng.ready(setupHamburgerMenu);
})(window, document, window.searxng);
