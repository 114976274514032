/* SPDX-License-Identifier: AGPL-3.0-or-later */
/* global searxng */
(function (w, d, searxng) {
  "use strict";
  function addDropDownClickHandler (dropDownElement, chevron) {
    for (const eventName of ["click", "keydown"]) {
      dropDownElement.addEventListener(eventName, function (event) {
        if (eventName === "keydown" && !["Enter", "Spacebar", " "].includes(event.key)) {
          return;
        }
        dropDownElement.classList.toggle("open");
        if (dropDownElement.classList.contains("open")) {
          chevron.classList.replace("down", "up");
        } else {
          chevron.classList.replace("up", "down");
        }
      });
    }
  }

  function addDropDownOptionClickHandler (
    optionsWrapper,
    customSelect,
    customSelectTrigger,
    chevron,
    hiddenInput
  ) {
    for (const option of optionsWrapper) {
      for (const eventName of ["click", "keydown"]) {
        option.addEventListener(eventName, function (event) {
          if (eventName === "keydown" && !["Enter", "Spacebar", " "].includes(event.key)) {
            return;
          }
          event.stopPropagation();
          for (const opt of optionsWrapper) {
            opt.classList.remove("selected");
          }
          option.classList.add("selected");
          customSelectTrigger.textContent = option.textContent;
          hiddenInput.value = option.getAttribute("data-value");
          customSelect.classList.remove("open");
          chevron.classList.replace("up", "down");
          if (searxng.endpoint === "results") {
            searxng.submitIfQuery();
          }
        });
      }
    }
  }

  function closeDropDownOnOutsideClick (dropDownElement, chevron) {
    d.addEventListener("click", function (event) {
      if (!dropDownElement.contains(event.target)) {
        dropDownElement.classList.remove("open");
        chevron.classList.remove("up");
        chevron.classList.add("down");
      }
    });
  }

  function addSelectedAttributeToOption (
    optionsWrapper,
    customSelectTrigger,
    hiddenInput
  ) {
    const initialValue = hiddenInput.value;
    for (const option of optionsWrapper) {
      if (option.getAttribute("data-value") === initialValue) {
        option.classList.add("selected");
        customSelectTrigger.textContent = option.textContent;
      }
    }
  }

  function createDropDownHandle (wrapperSelector) {
    const customSelectWrapper = d.querySelector(wrapperSelector);
    const customSelect = customSelectWrapper.querySelector(".custom-select");
    const customSelectTrigger = customSelect.querySelector(
      ".custom-select-trigger"
    );
    const customOptions = customSelect.querySelectorAll(".custom-option");
    const hiddenInput = d.querySelector(
      `input[name=${customSelectWrapper.dataset.selectId}]`
    );
    const chevron = customSelect.querySelector(".chevron");

    addDropDownClickHandler(customSelect, chevron);
    addDropDownOptionClickHandler(
      customOptions,
      customSelect,
      customSelectTrigger,
      chevron,
      hiddenInput
    );
    closeDropDownOnOutsideClick(customSelect, chevron);
    addSelectedAttributeToOption(
      customOptions,
      customSelectTrigger,
      hiddenInput
    );

    chevron.classList.add("down");
  }

  searxng.customSelect = createDropDownHandle;
})(window, document, searxng);
