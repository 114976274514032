/* SPDX-License-Identifier: AGPL-3.0-or-later */
/* global AutoComplete */
(function (w, d, searxng) {
  'use strict';
  const qinput_id = "q";
  let qinput = null;

  const isMobile = w.matchMedia("only screen and (max-width: 50em)").matches;

  function createClearButton (qinput) {
    const clearSearchButton = d.getElementById('clear_search');
    const verticalSeparator = d.getElementById("vertical_seperator");

    const updateClearButton = function () {
      if (qinput.value.length === 0) {
        clearSearchButton.classList.add("empty");
        if (verticalSeparator) {
          verticalSeparator.classList.add("empty");
        }
      } else {
        clearSearchButton.classList.remove("empty");
        if (verticalSeparator) {
          verticalSeparator.classList.remove("empty");
        }
      }
    };

    // update status, event listener
    updateClearButton();

    clearSearchButton.addEventListener('click', function (ev) {
      qinput.value = '';
      qinput.focus();
      updateClearButton();
      ev.preventDefault();
    });

    qinput.addEventListener('keyup', updateClearButton, false);
  }

  function scrollToGiveroAbout () {
    const scrollArrow = d.getElementById('home_page_arrow_wrapper');
    if (scrollArrow) {
      scrollArrow.addEventListener('click', function () {
        const aboutGivero = d.getElementById('about-givero');
        if (aboutGivero) {
          aboutGivero.scrollIntoView({ behavior: 'smooth' });
        }
      });
    }
  }

  searxng.ready(function () {
    qinput = d.getElementById(qinput_id);
    scrollToGiveroAbout();

    searxng.submitIfQuery = function () {
      if (qinput.value.length  > 0) {
        const search = d.getElementById('search');
        setTimeout(search.submit.bind(search), 0);
      }
    }

    if (qinput !== null) {
      // clear button
      createClearButton(qinput);

      // autocompleter
      if (searxng.settings.autocomplete_provider) {
        searxng.autocomplete = AutoComplete.call(w, {
          Url: "./autocompleter",
          EmptyMessage: searxng.settings.translations.no_item_found,
          HttpMethod: searxng.settings.http_method,
          HttpHeaders: {
            "Content-type": "application/x-www-form-urlencoded",
            "X-Requested-With": "XMLHttpRequest"
          },
          MinChars: searxng.settings.autocomplete_min,
          Delay: 150,
          Limit: 8,
          _Render: function (response) {
            let ul;

            if (typeof response == "string") {
              ul = this._RenderRaw(response);
            } else {
              ul = this._RenderResponseItems(response);
            }

            if (this.DOMResults.hasChildNodes()) {
              while (this.DOMResults.firstChild) {
                this.DOMResults.removeChild(this.DOMResults.firstChild)
              }
            }

            const separator = d.createElement('hr');
            separator.className = 'autocomplete-separator';
            this.DOMResults.appendChild(separator);

            this.DOMResults.appendChild(ul);
          },
          _Close: function () {
            if (this.DOMResults) {
              const element = this.DOMResults;
              element.setAttribute("class", "autocomplete");

              while (element.firstChild) {
                element.removeChild(element.firstChild);
              }
            }
          },
          _Position: function () {},
          _Open: function () {
            const params = this;
            Array.prototype.forEach.call(this.DOMResults.getElementsByTagName("li"), function (li) {
              if (li.getAttribute("class") != "locked") {
                li.onmousedown = function () {
                  params._Select(li);
                };
              }
            });
          },
          _Select: function (item) {
            AutoComplete.defaults._Select.call(this, item);
            const form = item.closest('form');
            if (form) {
              form.submit();
            }
          },
          _MinChars: function () {
            if (this.Input.value.indexOf('!') > -1) {
              return 0;
            } else {
              return AutoComplete.defaults._MinChars.call(this);
            }
          },
          KeyboardMappings: Object.assign({}, AutoComplete.defaults.KeyboardMappings, {
            "KeyUpAndDown_up": Object.assign({}, AutoComplete.defaults.KeyboardMappings.KeyUpAndDown_up, {
              Callback: function (event) {
                AutoComplete.defaults.KeyboardMappings.KeyUpAndDown_up.Callback.call(this, event);
                const liActive = this.DOMResults.querySelector("li.active");
                if (liActive) {
                  AutoComplete.defaults._Select.call(this, liActive);
                }
              },
            }),
            "Tab": Object.assign({}, AutoComplete.defaults.KeyboardMappings.Enter, {
              Conditions: [{
                Is: 9,
                Not: false
              }],
              Callback: function (event) {
                if (this.DOMResults.getAttribute("class").indexOf("open") != -1) {
                  const liActive = this.DOMResults.querySelector("li.active");
                  if (liActive !== null) {
                    AutoComplete.defaults._Select.call(this, liActive);
                    event.preventDefault();
                  }
                }
              },
            })
          }),
        }, "#" + qinput_id);
      }

      /*
        Monkey patch autocomplete.js to fix a bug
        With the POST method, the values are not URL encoded: query like "1 + 1" are sent as "1  1" since space are URL encoded as plus.
        See HTML specifications:
        * HTML5: https://url.spec.whatwg.org/#concept-urlencoded-serializer
        * HTML4: https://www.w3.org/TR/html401/interact/forms.html#h-17.13.4.1

        autocomplete.js does not URL encode the name and values:
        https://github.com/autocompletejs/autocomplete.js/blob/87069524f3b95e68f1b54d8976868e0eac1b2c83/src/autocomplete.ts#L665

        The monkey patch overrides the compiled version of the ajax function.
        See https://github.com/autocompletejs/autocomplete.js/blob/87069524f3b95e68f1b54d8976868e0eac1b2c83/dist/autocomplete.js#L143-L158
        The patch changes only the line 156 from
          params.Request.send(params._QueryArg() + "=" + params._Pre());
        to
          params.Request.send(encodeURIComponent(params._QueryArg()) + "=" + encodeURIComponent(params._Pre()));

        Related to:
        * https://github.com/autocompletejs/autocomplete.js/issues/78
        * https://github.com/searxng/searxng/issues/1695
       */
      AutoComplete.prototype.ajax = function (params, request, timeout) {
        if (timeout === void 0) { timeout = true; }
        if (params.$AjaxTimer) {
          window.clearTimeout(params.$AjaxTimer);
        }
        if (timeout === true) {
          params.$AjaxTimer = window.setTimeout(AutoComplete.prototype.ajax.bind(null, params, request, false), params.Delay);
        } else {
          if (params.Request) {
            params.Request.abort();
          }
          params.Request = request;
          params.Request.send(encodeURIComponent(params._QueryArg()) + "=" + encodeURIComponent(params._Pre()));
        }
      };

      if (!isMobile && d.querySelector('.index_endpoint')) {
        qinput.focus();
      }
    }

    // most common browsers at the time of writing this support :has, except for Firefox
    // can be removed when Firefox / Firefox ESL starts supporting it as well
    try {
      // this fails when the browser does not support :has
      d.querySelector("html:has(body)");
    } catch (_) {
      // manually deselect the old selection when a new category is selected
      for (let button of d.querySelectorAll("button.category_button")) {
        searxng.on(button, 'click', () => {
          const selected = d.querySelector("button.category_button.selected");
          selected.classList.remove("selected");
        })
      }
    }
  });

})(window, document, window.searxng);
